export default {
  type: process.env.REACT_APP_LEGACY_SERVICE_TYPE,
  endpoints: {
    legacy: {
      root: process.env.REACT_APP_LEGACY_SERVICE_ROOT,
      users: process.env.REACT_APP_LEGACY_SERVICE_USERS,
      customerServiceBookings: process.env.REACT_APP_LEGACY_SERVICE_CUSTOMERSERVICE_BOOKINGS,
      customerServiceMarkets: process.env.REACT_APP_LEGACY_SERVICE_CUSTOMERSERVICE_MARKETS,
      extension: process.env.REACT_APP_LEGACY_SERVICE_EXTENSION,
      availabilities: process.env.REACT_APP_LEGACY_SERVICE_AVAILABILITIES,
      bookings: process.env.REACT_APP_LEGACY_SERVICE_BOOKINGS,
      comments: process.env.REACT_APP_LEGACY_SERVICE_COMMENTS,
      sendbird: process.env.REACT_APP_LEGACY_SERVICE_SENDBIRD,
      userreports: process.env.REACT_APP_SERVICE_USERREPORTS,
      historylog: process.env.REACT_APP_LEGACY_SERVICE_HISTORYLOG,
    },
    main: {
      root: process.env.REACT_APP_SERVICE_ROOT,
      users: process.env.REACT_APP_SERVICE_USERS,
      customerServiceBookings: process.env.REACT_APP_SERVICE_BOOKINGS,
      extension: process.env.REACT_APP_LEGACY_SERVICE_EXTENSION,
      availabilities: process.env.REACT_APP_LEGACY_SERVICE_AVAILABILTIES,
      bookings: process.env.REACT_APP_LEGACY_SERVICE_BOOKINGS,
      userreports: process.env.REACT_APP_SERVICE_USERREPORTS,
    },
  },
};

import { Button, GlobalContext, Input, Table, Tbody, Td, Th, Thead, Tr } from 'lynkco-up-core';
import { useContext, useEffect, useState } from 'react';
import { EditMarketInfo, GetMarketInfo, getCountryByCode } from '../../services/bookings/types';
import editMarket from '../../services/markets/editMarkets';
import getMarkets from '../../services/markets/getMarkets';
import { PageHeading } from '../../components';
import { handleAction } from '../HistoryLog/historyLogService';
import { ActionStatus } from '../HistoryLog/types';

const ServiceFees = () => {
  const { alert } = useContext(GlobalContext);
  async function fetchMarketInfos() {
    try {
      const fetchedMarketInfos: GetMarketInfo[] = await getMarkets();
      setMarkets(fetchedMarketInfos);
    } catch (error) {
      alert.show('Failed to fetch markets:', 'error');
    }
  }
  useEffect(() => {
    fetchMarketInfos();
  }, []);
  const [selectedPriceLevel] = useState('FEE_PRICE_LEVEL_2');
  const [markets, setMarkets] = useState<GetMarketInfo[]>([]);

  // This is commented until we implement different price levels in the market
  // const handlePriceLevelChange = (event: any) => {
  //   setSelectedPriceLevel(event.target.checked ? 'FEE_PRICE_LEVEL_2' : 'FEE_PRICE_LEVEL_1');
  // };

  const submitFeeChange = () => {
    markets.map(market => {
      if (market.isChanged) {
        const editModel: EditMarketInfo = {
          fuel: market.fuelPerLiter,
          buffer: market.buffer,
          currency: market.currency,
          maxCostLimitPerBooking: market.maxCostLimitPerBooking,
          vat: market.vat,
          serviceFees: market.serviceFees,
        };
        editMarket(editModel, market.market)
          .then(() => {
            fetchMarketInfos();
            handleAction(ActionStatus.MODIFYING_SERVICE_FEE, [{ market: market.market }]);
            alert.show('Successfully updated market', 'success');
          })
          .catch(() => {
            fetchMarketInfos();
            alert.show('Unable to update market', 'error');
          });
      }
    });
  };

  const durations = [
    { title: '0-6 hours', durationKey: 'FEE_DURATION_0_H_TO_6_H' },
    { title: '6-24 hours', durationKey: 'FEE_DURATION_6_H_TO_24_H' },
    { title: '24 hours - 5 days', durationKey: 'FEE_DURATION_24_H_TO_5_DAYS' },
    { title: '5-10 days', durationKey: 'FEE_DURATION_5_DAYS_TO_10_DAYS' },
    { title: '10-28 days', durationKey: 'FEE_DURATION_10_DAYS_TO_28_DAYS' },
  ];

  const renderTableHeader = () => {
    return (
      <Thead>
        <Tr>
          <Th>
            {' '}
            <span>Country</span>
          </Th>
          <Th>
            {' '}
            <span>Currency</span>
          </Th>
          <>
            {durations.map(duration => (
              <Th key={duration.durationKey} extraClasses="mx-auto">
                <span>{duration.title}</span>
              </Th>
            ))}
          </>
        </Tr>
      </Thead>
    );
  };

  const renderTableBody = () => {
    return (
      <Tbody>
        {markets?.map(item => (
          <Tr key={item.market}>
            <Td extraClasses="px-4 py-2 font-bold">{getCountryByCode(item.market)}</Td>
            <Td>
              <span className="font-light">{item.currency}</span>
            </Td>

            <>
              {durations.map(duration => {
                const fee = item.serviceFees.find(
                  fee => fee.duration === duration.durationKey && fee.priceLevel === selectedPriceLevel,
                );
                return (
                  <Td key={duration.durationKey}>
                    <div className="flex items-center">
                      <Input
                        type="number"
                        value={fee ? fee.fee : ''}
                        onChange={e => handleFeeChange(item.market, duration.durationKey, e.currentTarget.value)}
                      />
                    </div>
                  </Td>
                );
              })}
            </>
          </Tr>
        ))}
      </Tbody>
    );
  };

  const handleFeeChange = (market: string, duration: string, newFee: string) => {
    const updatedData = markets.map(item => {
      if (item.market === market) {
        const updatedFees = item.serviceFees.map(fee => {
          if (fee.duration === duration && fee.priceLevel === selectedPriceLevel) {
            item.isChanged = true;
            return { ...fee, fee: parseFloat(newFee) };
          }
          return fee;
        });
        return { ...item, serviceFees: updatedFees };
      }
      return item;
    });
    setMarkets(updatedData);
  };

  return (
    <div className="min-h-screen">
      <PageHeading
        title="Service Fees (Excluding VAT)"
        description="A list of country, durations with service fees list."></PageHeading>
      <Table extraClasses="min-w-max">
        {renderTableHeader()}
        {renderTableBody()}
      </Table>
      <Button
        onClick={submitFeeChange}
        variant="primary"
        extraClasses="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">
        Submit
      </Button>
    </div>
  );
};

export default ServiceFees;

import { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Dialog, Icon, Sidebar, SidebarItem } from 'lynkco-up-core';
import { GenericFunction } from 'lynkco-up-core/dist/types/types';
import { IconName } from 'lynkco-up-core/dist/types/components/Icon';

import { routes } from '../../routing';
import useLogout from '../../hooks/useLogout';
import msalInstance from '../../services/shared/auth';
import { useUserContext } from '../../modules/Users/UserContext';

type NavSection = {
  name: string;
  links: {
    label: string;
    icon?: IconName;
    path: string;
    badgeText?: string;
    current?: boolean;
    onClick?: GenericFunction;
    isAvailable?: boolean;
    accessScope: string;
  }[];
};
const navSections: NavSection[] = [
  {
    name: 'Applications',
    links: [
      {
        label: routes.applications.name,
        icon: 'badge',
        path: routes.applications.path,
        isAvailable: routes.applications.isAvailable,
        accessScope: 'VIEW_APPLICATIONS',
      },
      {
        label: routes.gdpr.name,
        icon: 'fingerprint',
        path: routes.gdpr.path,
        isAvailable: routes.gdpr.isAvailable,
        accessScope: 'VIEW_APPLICATIONS',
      },
    ],
  },
  {
    name: 'Bookings',
    links: [
      {
        label: routes.availabilities.name,
        icon: 'local_shipping',
        path: routes.availabilities.path,
        isAvailable: routes.availabilities.isAvailable,
        accessScope: 'VIEW_BOOKINGS',
      },
      {
        label: routes.bookings.name,
        icon: 'auto_awesome',
        path: routes.bookings.path,
        isAvailable: routes.bookings.isAvailable,
        accessScope: 'VIEW_BOOKINGS',
      },
    ],
  },
  {
    name: 'Error Detection',
    links: [
      {
        label: routes.bookingrequesterror.name,
        icon: 'warning',
        path: routes.bookingrequesterror.path,
        isAvailable: routes.bookingrequesterror.isAvailable,
        accessScope: 'VIEW_BOOKINGS',
      },
    ],
  },
  {
    name: 'Management',
    links: [
      {
        label: routes.users.name,
        icon: 'person_add',
        path: routes.users.path,
        isAvailable: routes.users.isAvailable,
        accessScope: 'VIEW_MANAGEMENT',
      },
      {
        label: routes.export.name,
        icon: 'file_download',
        path: routes.export.path,
        isAvailable: routes.export.isAvailable,
        accessScope: 'VIEW_MANAGEMENT',
      },
      {
        label: routes.pricing.name,
        icon: 'monetization_on',
        path: routes.pricing.path,
        isAvailable: routes.pricing.isAvailable,
        accessScope: 'VIEW_MANAGEMENT',
      },
      {
        label: routes.servicefees.name,
        icon: 'monetization_on',
        path: routes.servicefees.path,
        isAvailable: routes.servicefees.isAvailable,
        accessScope: 'EDIT_SERVICE_FEE',
      },
      {
        label: routes.historylog.name,
        icon: 'edit_note',
        path: routes.historylog.path,
        isAvailable: routes.historylog.isAvailable,
        accessScope: 'EDIT_SERVICE_FEE',
      },
      {
        label: routes.energyfees.name,
        icon: 'monetization_on',
        path: routes.energyfees.path,
        isAvailable: routes.energyfees.isAvailable,
        accessScope: 'EDIT_SERVICE_FEE',
      },
    ],
  },
  {
    name: 'Chat Dashboard',
    links: [
      {
        label: routes.sendbirdreports.name,
        icon: 'chat_bubble',
        path: routes.sendbirdreports.path,
        isAvailable: routes.sendbirdreports.isAvailable,
        accessScope: 'VIEW_SENDBIRD',
      },
    ],
  },
];

function Layout() {
  const isSidebarVisibleAtStart = window.innerWidth >= 1280;
  const currentPath = useLocation().pathname.split('/').reverse()[0];
  const navigate = useNavigate();
  const [isSidebarVisible, setIsSidebarVisible] = useState(isSidebarVisibleAtStart);
  const sidebarWidth = '15rem';
  const sidebarOffset = isSidebarVisible ? 0 : `-${sidebarWidth}`;
  const outletWidth = isSidebarVisible ? `calc(100% - ${sidebarWidth})` : '100%';
  const outletOffset = isSidebarVisible ? sidebarWidth : 0;
  const sidebarToggleContainerClasses = isSidebarVisible
    ? 'fixed bottom-2 left-44 z-20 transition-all'
    : 'fixed bottom-2 left-2 z-20 opacity-50 hover:opacity-100 transition-all';
  const sidebarToggleIcon = isSidebarVisible ? 'chevron_left' : 'chevron_right';
  const logout = useLogout(msalInstance);
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);

  const navItems = navSections
    .filter(navItem => navItem.links.find(link => link.isAvailable))
    .filter(navItem => navItem.links.find(link => hasManagementAccess(link.accessScope)))
    .map(navItem => ({
      ...navItem,
      links: navItem.links.map(link => ({
        ...link,
        current: link.path.includes(currentPath),
        onClick: () => handleNavigate(link.path),
      })),
    }));

  function handleNavigate(to: string) {
    navigate(to);
  }

  function handleToggleSidebar() {
    setIsSidebarVisible(!isSidebarVisible);
  }

  function handleLogout() {
    logout();
  }

  function handleOpenLogoutDialog() {
    setIsLogoutDialogOpen(true);
  }

  function handleCloseLogoutDialog() {
    setIsLogoutDialogOpen(false);
  }

  function hasManagementAccess(scope: string) {
    const { checkAccess } = useUserContext();
    return checkAccess(scope);
  }
  return (
    <div className="flex relative h-screen w-full">
      <div className={sidebarToggleContainerClasses}>
        <button
          className="bg-primary-100 rounded-lg text-primary-700 p-3 flex hover:bg-primary-300 hover:text-white transition-all"
          aria-label="toggle-sidebar"
          onClick={handleToggleSidebar}>
          <span className="m-icon icon-18">{sidebarToggleIcon}</span>
        </button>
      </div>
      <div className="h-screen fixed transition-all z-10" style={{ left: sidebarOffset }}>
        <Sidebar extraClasses="w-60">
          {navItems.map(navbarItem => (
            <div key={navbarItem.name}>
              <h3 className="mt-8 mb-2 text-xs font-semibold text-gray-600">{navbarItem.name}</h3>
              {navbarItem.links
                .filter(({ isAvailable }) => isAvailable)
                .filter(navbarItem => hasManagementAccess(navbarItem.accessScope))
                .map(({ label, icon, onClick, badgeText, current }) => (
                  <SidebarItem
                    key={label}
                    label={label}
                    icon={icon}
                    current={current}
                    onClick={onClick}
                    badgeText={badgeText}
                  />
                ))}
            </div>
          ))}
          <button
            className="warning-button border-b w-full absolute bottom-16 flex justify-center items-center"
            onClick={() =>
              window.open(
                'https://lynkcoknowledgehub.atlassian.net/wiki/spaces/ECH/pages/228950254/Car+Location+Mgmt+Car+Sharing+Booking+Tool',
              )
            }>
            <span className="bottom-2">Release 19.0</span>
            <Icon name="open_in_new" />
          </button>
        </Sidebar>
        <div className="absolute bottom-2 left-2">
          <SidebarItem label="Sign out" icon="exit_to_app" onClick={handleOpenLogoutDialog} />
        </div>
      </div>
      <div className="relative transition-all overflow-x-hidden" style={{ width: outletWidth, left: outletOffset }}>
        <Outlet />
      </div>
      <Dialog
        isOpen={isLogoutDialogOpen}
        title="Confirm logout"
        body="Are you sure you want to log out of the application?"
        confirmText="Yes"
        cancelText="No"
        icon="exit_to_app"
        onConfirm={handleLogout}
        onCancel={handleCloseLogoutDialog}
        onClose={handleCloseLogoutDialog}
      />
    </div>
  );
}

export default Layout;

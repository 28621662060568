import { serviceConfig } from '../../config';
import { axiosInstance } from '../../services/shared';
import { ActionStatus } from './types';

const handleAction = async (action: ActionStatus, data: Array<{ [key: string]: string }>) => {
  try {
    await axiosInstance.post(`${serviceConfig.endpoints.legacy.root}${serviceConfig.endpoints.legacy.historylog}`, {
      action,
      data,
    });
  } catch (error) {
    console.error('Error:', error);
  }
};

export { handleAction };

import { useContext, useEffect, useState } from 'react';
import { ColDefinitionDetails, RowDefinitionDetails } from '../../components';
import { Booking, BookingEvent, BookingEventKeys, GetProvision, GetUsageEvent } from '../../services/bookings/types';
import { DetailsSection, DetailsSectionHeading, EquipmentList, timeStampToDate } from '../shared/utils';
import { isReturnedBooking } from './utils';
import { GlobalContext } from 'lynkco-up-core';
import getUsageEvents from '../../services/bookings/getUsageEvents';
import getProvisions from '../../services/bookings/getProvision';

type VehicleAndFuelProps = {
  booking: Booking;
};

function VehicleAndFuel({ booking }: VehicleAndFuelProps) {
  const { alert } = useContext(GlobalContext);
  const hasBookingEnded = isReturnedBooking(booking);
  const [events, setEvents] = useState<GetUsageEvent[]>([]);
  const [provisionStates, setProvisionStates] = useState<GetProvision[]>([]);

  async function fetchEvents(bookingId: string) {
    try {
      const fetchedEvents: GetUsageEvent[] = await getUsageEvents({ bookingId });
      setEvents(fetchedEvents);
    } catch (error) {
      alert.show('Failed to fetch events:', 'error');
    }
  }
  useEffect(() => {
    fetchEvents(booking.id);
  }, []);

  function getCreatedAtForState(stateToFind: string) {
    const state = provisionStates.find(provision => provision.state === stateToFind);
    return state ? state.createdAt : '';
  }

  async function fetchProvision(bookingId: string) {
    try {
      const fetchedProvision: GetProvision[] = await getProvisions({ bookingId });
      setProvisionStates(fetchedProvision);
      console.log(fetchedProvision);
    } catch (error) {
      alert.show('Failed to fetch events:', 'error');
    }
  }
  useEffect(() => {
    fetchProvision(booking.id);
  }, []);

  const provisionCreatedTime = timeStampToDate(getCreatedAtForState('CREATE_PROVISIONED'));
  const provisionRemovedTime = timeStampToDate(getCreatedAtForState('REMOVED_CONFIRMED'));

  return (
    <div>
      {hasBookingEnded && (
        <DetailsSection>
          <DetailsSectionHeading>Fuel</DetailsSectionHeading>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <ColDefinitionDetails label="Fuel per liter price">
              {booking.cost.fuelPerLiter?.toFixed(2) + ' ' + booking.cost.currency}
            </ColDefinitionDetails>
            <ColDefinitionDetails label="Fuel Cost">
              {booking.cost.fuel + ' ' + booking.cost.currency}{' '}
            </ColDefinitionDetails>
            <ColDefinitionDetails label="Base Cost">
              {booking.cost.base + ' ' + booking.cost.currency}{' '}
            </ColDefinitionDetails>
            <ColDefinitionDetails label="Service Cost">
              {booking.cost.service + ' ' + booking.cost.currency}{' '}
            </ColDefinitionDetails>
            <ColDefinitionDetails label="Total Cost">
              {booking.cost.total + ' ' + booking.cost.currency}
            </ColDefinitionDetails>
            <ColDefinitionDetails label=""></ColDefinitionDetails>
            <ColDefinitionDetails label="Odometer Start">{booking.odometerKmStart}</ColDefinitionDetails>
            <ColDefinitionDetails label="Odometer End">{booking.odometerKmEnd}</ColDefinitionDetails>
          </dl>
        </DetailsSection>
      )}
      <DetailsSection>
        <DetailsSectionHeading>Vehicle</DetailsSectionHeading>
        <div className="border-t">
          <dl className="divide-y divide-gray-200">
            <RowDefinitionDetails label="VIN">{booking.vehicle.vin}</RowDefinitionDetails>
            <RowDefinitionDetails label="License plate">{booking.vehicle.regNr}</RowDefinitionDetails>
            <RowDefinitionDetails label="Type">{booking.vehicle.model}</RowDefinitionDetails>
            <RowDefinitionDetails label="Equipment">
              <EquipmentList data={booking.vehicle.equipment} />
            </RowDefinitionDetails>
            <RowDefinitionDetails label="Market">{booking.vehicle.market}</RowDefinitionDetails>
          </dl>
        </div>
      </DetailsSection>
      <DetailsSection>
        <DetailsSectionHeading>Digital key</DetailsSectionHeading>
        <div className="border-t border-gray-200">
          <dl className="divide-y divide-gray-200">
            <RowDefinitionDetails label="Borrower key in car">{provisionCreatedTime}</RowDefinitionDetails>
            <RowDefinitionDetails label="Key Removed">{provisionRemovedTime}</RowDefinitionDetails>
            <RowDefinitionDetails label="Key ID">{booking.digitalKeyId}</RowDefinitionDetails>
          </dl>
        </div>
      </DetailsSection>

      <DetailsSection>
        <DetailsSectionHeading>Usage Events</DetailsSectionHeading>
        <div className="border-t border-gray-200">
          <dl className="divide-y divide-gray-200">
            {events?.map(event => (
              <RowDefinitionDetails key={event.createdAt} label={BookingEvent[event.event as BookingEventKeys]}>
                {timeStampToDate(event.createdAt)}
              </RowDefinitionDetails>
            ))}
          </dl>
        </div>
      </DetailsSection>
    </div>
  );
}

export default VehicleAndFuel;

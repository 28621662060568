import { Helmet } from 'react-helmet';
import { PageHeading } from '../../components';
import HistoryLogsTable from './HistoryLogsTable';

function HistoryLog() {
  return (
    <div>
      <div className="z-10 bookings-table">
        <Helmet>
          <title>HISTORY LOG</title>
        </Helmet>
        <PageHeading title="History log" description="History logs of EC agents actions"></PageHeading>
      </div>

      <div>
        <HistoryLogsTable />
      </div>
    </div>
  );
}

export default HistoryLog;

import { Table, Thead, Th, Tr, Td, Button, Tbody, Icon } from 'lynkco-up-core';
import { useState, useEffect } from 'react';
import { LoadingMask } from '../../components';
import { GetHistoryLog } from './types';
import { getHistoryLogs } from './getHistoryLog';
import ActionStatusDisk from '../../components/ActionStatuDisk';

function HistoryLogsTable() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<GetHistoryLog[]>([]);
  const fillerArray = new Array(5).fill(0).map(() => Math.random());
  const hasData = data.length > 0;
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = Math.ceil((data?.length || 0) / perPage);
  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;
  const paginatedRecords = data?.slice(startIndex, endIndex) || [];

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < pageCount) {
      handlePageChange(currentPage + 1);
    }
  };

  function handleIndex() {
    if (currentPage > pageCount) setCurrentPage(1);
    setPerPage(20);
  }

  useEffect(() => {
    handleIndex();
  }, [data]);

  function handlePerPageSelectChange(value: string): void {
    setPerPage(Number(value));
    setCurrentPage(1);
  }

  async function fetchData() {
    try {
      const historyLogs = await getHistoryLogs();
      setData(historyLogs);
      console.log(historyLogs);
    } catch (error) {
      console.error('Error occurred while fetching history logs:', error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);

    const hour = date.getHours();
    const minute = date.getMinutes();

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthIndex = date.getMonth();
    const month = months[monthIndex];

    const formattedDate = `${hour.toString().padStart(2, '0')}:${minute
      .toString()
      .padStart(2, '0')}, ${month} ${date.getDate()} ${date.getFullYear()}`;

    return formattedDate;
  };

  function handleOpenLogs() {
    // TODO: here it opens the more details page
  }

  return (
    <div className="z-10 availabilities-table mb-72">
      <div className="overflow-x-scroll">
        <Table extraClasses="min-w-max">
          <Thead extraClasses="!bg-neutral-50">
            {isLoading ? (
              <Tr>
                <Th>
                  <div className="pl-6 py-1.5">
                    <LoadingMask />
                  </div>
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
                <Th>
                  <LoadingMask />
                </Th>
              </Tr>
            ) : (
              <Tr>
                <Th extraClasses="!p-0 hover:bg-gray-200">
                  <span className="font-medium text-sm text-neutral-400 flex justify-start ml-6">Changed Date</span>
                </Th>
                <Th>
                  <span className="font-medium text-sm text-neutral-400 flex justify-start">Changed By</span>
                </Th>
                <Th>
                  <span className="font-medium text-sm text-neutral-400 flex items-center justify-start ml-6">
                    Action
                  </span>
                </Th>
                {/* <Th>
                  <span className="font-medium text-sm text-neutral-400 flex justify-start">Data</span>
                </Th> */}
                <Th>
                  <span></span>
                </Th>
              </Tr>
            )}
          </Thead>
          <Tbody>
            {isLoading
              ? fillerArray.map(_ => (
                  <Tr key={_}>
                    <Td>
                      <div className="pl-5 py-3">
                        <LoadingMask />
                      </div>
                    </Td>
                    <Td>
                      <LoadingMask />
                    </Td>
                    <Td>
                      <LoadingMask />
                    </Td>
                    <Td>
                      <LoadingMask />
                    </Td>
                    <Td cellWidth={100} align="right">
                      <LoadingMask />
                    </Td>
                  </Tr>
                ))
              : paginatedRecords.map((item, index) => (
                  <Tr key={index}>
                    {/* Render columns for each action */}
                    <Td>
                      <span className="font-medium pl-6 text-black flex justify-start">
                        {' '}
                        {formatDate(item.actionDate)}
                      </span>
                    </Td>
                    <Td> {item.user?.username}</Td>
                    <Td>
                      <div className="text-gray-900 flex justify-start pl-6 items-center font-medium">
                        <ActionStatusDisk actions={[item.action]} extraClasses="mr-2" /> {item.action}
                      </div>
                    </Td>
                    {/* <Td> {JSON.stringify(item.data?.length > 1 ? item.data : item.data?.[0])}</Td> */}
                    <Td cellWidth={100} align="right">
                      <div className="flex items-center justify-end pr-2">
                        <Button onClick={() => handleOpenLogs()} variant="transparent" size="small" extraClasses="mr-2">
                          <Icon name="list_alt" size="small" variant="gray" />
                        </Button>
                      </div>
                    </Td>
                  </Tr>
                ))}
          </Tbody>
        </Table>
        {!isLoading && !hasData && <p className="text-center text-2xl m-8 text-gray-500">No History Logs found</p>}
      </div>
      <div className="mt-4 flex justify-between p-6 border-t">
        <Button
          onClick={goToPreviousPage}
          extraClasses={`px-3 py-2 mx-1 rounded-md ${
            currentPage === pageCount ? 'bg-gray-300 text-gray-600' : 'bg-blue-500 text-white'
          }`}
          isDisabled={currentPage === 1}
          variant="white">
          Previous Page
        </Button>
        <div>
          <label htmlFor="perPageSelect" className="text-gray-500">
            Records per page:{' '}
          </label>
          <select
            id="perPageSelect"
            className="w-16  p-2 border rounded-md bg-white"
            value={perPage}
            onChange={e => handlePerPageSelectChange(e.target.value)}>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={data?.length + 1}>all</option>
          </select>
        </div>
        <div className="text-gray-500">
          Page {currentPage} of {pageCount}
        </div>
        <Button
          onClick={goToNextPage}
          extraClasses={`px-3 py-2 mx-1 rounded-md ${
            currentPage === pageCount ? 'bg-gray-300 text-gray-600' : 'bg-blue-500 text-white'
          }`}
          isDisabled={currentPage === pageCount}
          variant="white">
          Next Page
        </Button>
      </div>
    </div>
  );
}

export default HistoryLogsTable;

import { useContext } from 'react';

import { Button, Icon, Pagination, Table, Tbody, Td, Th, Thead, Tr, GlobalContext } from 'lynkco-up-core';
import { GenericFunction } from 'lynkco-up-core/dist/types/types';
import { BookingRequestErrorData } from '../../services/bookings/types';
import { copyToClipboard } from '../../utils';
import { formatDetailsDate } from '../shared/utils';
import { LoadingMask } from '../../components';

type TableProps = {
  data: BookingRequestErrorData[];
  isLoading?: boolean;
  paginationStartIndex: number;
  paginationEndIndex: number;
  paginationQuantity: number;
  onPaginationNextChange: GenericFunction;
  onPaginationPreviousChange: GenericFunction;
  customerId: string;
};

function RequestErrorsTable({
  data,
  isLoading,
  paginationStartIndex,
  paginationEndIndex,
  paginationQuantity,
  onPaginationNextChange,
  onPaginationPreviousChange,
  customerId,
}: TableProps): JSX.Element {
  const { alert } = useContext(GlobalContext);
  const hasData = data.length > 0;

  function handleCopy(requestError: BookingRequestErrorData) {
    copyToClipboard(JSON.stringify({ customerId, ...requestError }, undefined, 2), () => {
      alert.show('Error copying to clipboard.');
    });
  }
  return (
    <div className="z-10 bookings-table">
      <div className="overflow-x-scroll">
        <Table>
          <Thead>
            <Tr>
              <Th extraClasses="w-3/4">
                <span className="font-medium pl-6"> User ID</span>
              </Th>
              <Th extraClasses="w-3/4">
                <span className="font-medium pl-6">Error Code </span>
              </Th>
              <Th extraClasses="w-3/4">
                <span className="font-medium pl-6">Message</span>
              </Th>
              <Th extraClasses="w-3/4">
                <span className="font-medium pl-6">Created At</span>
              </Th>
              <Thead extraClasses="w-3/4">
                <span></span>
              </Thead>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((requestError, index) => (
              <Tr key={index}>
                <Td>
                  {isLoading ? (
                    <LoadingMask />
                  ) : (
                    <span className="font-medium pl-6 text-black">{requestError.customerNumber}</span>
                  )}
                </Td>
                <Td>
                  {isLoading ? (
                    <LoadingMask />
                  ) : (
                    <span className="font-medium pl-6 text-black">{requestError.ErrorCode}</span>
                  )}
                </Td>
                <Td>
                  {isLoading ? (
                    <LoadingMask />
                  ) : (
                    <span className="font-medium pl-6 text-black">{requestError.Message}</span>
                  )}
                </Td>
                <Td>
                  {isLoading ? (
                    <LoadingMask />
                  ) : (
                    <span className="font-medium pl-6 text-black">{formatDetailsDate(requestError.createdAt)}</span>
                  )}
                </Td>

                {isLoading ? (
                  <LoadingMask />
                ) : (
                  <Td cellWidth={100}>
                    <div className="flex items-center">
                      <Button onClick={() => handleCopy(requestError)} variant="transparent" size="small">
                        <Icon name="content_copy" />
                      </Button>
                    </div>
                  </Td>
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
        {!isLoading && !hasData && (
          <p className="text-center text-2xl mt-8 pl-8 mb-8 text-gray-500">
            No Booking Request Errors found for this customer.
          </p>
        )}
      </div>
      {!isLoading && hasData && (
        <div className="px-2.5">
          <Pagination
            itemsStartIndex={paginationStartIndex}
            itemsEndIndex={paginationEndIndex}
            itemsQuantity={paginationQuantity}
            onNextChange={onPaginationNextChange}
            onPreviousChange={onPaginationPreviousChange}
          />
        </div>
      )}
    </div>
  );
}

export default RequestErrorsTable;
